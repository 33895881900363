import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

export interface SecondaryNavList {
	listItem: string;
	path: string;
	feature: string;
	id: string;
	key: string;
}
@Injectable({
	providedIn: 'root'
})
export class SecondaryNavService {
	private toggleSecondaryNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private activeListItem$: BehaviorSubject<string> = new BehaviorSubject<string>('');
	private activeListItem = '';
	private isPermissionAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private secondaryNavListItems: SecondaryNavList[] = [];

	/**
	 * Set secondary nav list items
	 * @param secondaryNavListItems - array representation of list items
	 */
	public setSecondaryNavListItems(secondaryNavListItems: SecondaryNavList[]): void {
		this.secondaryNavListItems = secondaryNavListItems;
	}

	/**
	 * Get secondary nav list items
	 * @returns secondaryNavListItems - array representation of list items
	 */
	public getSecondaryNavListItems$(): Observable<SecondaryNavList[]> {
		return of(this.secondaryNavListItems);
	}

	/**
	 * Set toggle secondary header
	 * @param toggleSecondaryNav - boolean representation of toggle secondary header
	 */
	public setToggleSecondaryNav(toggleSecondaryNav: boolean): void {
		this.toggleSecondaryNav$.next(toggleSecondaryNav);
	}

	/**
	 * Get toggle secondary header
	 * @returns toggleSecondaryNav - boolean representation of toggle secondary header
	 */
	public getToggleSecondaryNav$(): Observable<boolean> {
		return this.toggleSecondaryNav$;
	}

	/**
	 * Set active list item
	 * @param activeListItem - string representation of active list item
	 */
	public setActiveListItem(activeListItem: string): void {
		this.activeListItem = activeListItem;
		this.activeListItem$.next(activeListItem);
	}

	/**
	 * Get active list item
	 * @returns activeListItem - string representation of active list item
	 */
	public getActiveListItem$(): Observable<string> {
		return this.activeListItem$;
	}

	/**
	 * Get active list item
	 * @returns activeListItem - string representation of active list item
	 */
	public getActiveListItem(): string {
		return this.activeListItem;
	}
	/**
	 * Set permission flag
	 * @param isPermissionAvailable - string representation of permission flag
	 */
	public setIsPermissionAvailable(isPermissionAvailable: boolean): void {
		this.isPermissionAvailable$.next(isPermissionAvailable);
	}

	/**
	 * Get permission flag
	 * @returns isPermissionAvailable - string representation of permission flag
	 */
	public getIsPermissionAvailable$(): Observable<boolean> {
		return this.isPermissionAvailable$;
	}
}
