import { LogLevel } from '@shure/shared/angular/utils/logging';

export interface AppEnvironment {
	production: boolean;
	remoteLogging: boolean;
	sessionInactivity: {
		idleWarnTime: number;
		idleGracePeriod: number;
	};
	authorization: {
		clientId: string; // eg. 0oa1a8hzfcYlIRPwy5d7
		oktaDomain: string; // eg. dev-2329998.okta.com
		authServerPath: string; // e.g. /oauth2/devault
		scopes: string[];
		enableSecondaryIDP: boolean;
		secondaryIDP: string;
	};
	useClassicEngine: boolean;
	sysApi?: {
		url: string;
		apiKey?: string;
	};
	orgs?: {
		groupsApiUrl: string;
		licensesApiUrl: string;
		orgsApiUrl: string;
		rolesApiUrl: string;
		usersApiUrl: string;
		userGroupsApiUrl?: string;
		pemissionsApiUrl: string;
		notificationsApiUrl: string;
		preferencesApiUrl: string;
		maintenanceApiUrl?: string;
		featureRequestApiUrl?: string;
	};
	appUrls?: {
		deviceUrl: string;
		deviceExtUrl?: string;
		rfscanUrl?: string;
		rfscanExtUrl?: string;
	};
	logLevel: LogLevel;
	useMockup?: boolean;
	appVersion: string;
	clientVersion?: string;
	showOnlyIdp: boolean;
	showMicStatus?: boolean;
	showControlNet?: boolean;
	showAudioNet?: boolean;
	showAbout?: boolean;
	showFirmwareUpgrade?: boolean;
	showProxiedDevices?: boolean;
	showUserSettings?: boolean;
	isDebugModeEnabled?: boolean;
	mixpanelProjectToken?: string;
	enableMixPanel?: boolean;
	applicationClientIds?: {
		orgPortalClientId: string;
		wwbScanLibClientId: string;
	};

	signInMethod?: 'okta-hosted' | 'okta-embedded-widget';
	signOutScope?: 'signout-app' | 'signout-okta';

	// The field to map a Uri pattern to the corresponding Shure Cloud Uri value.
	shureCloudUriMap?: {
		urlPattern: string;
		shureCloudUri: string;
	}[];
	appType: 'cloud' | 'device' | 'license' | 'admin' | 'maintenance';
	appRoles: ('organization-admin' | 'device-admin' | 'user-license-and-device-admin' | 'license-admin')[];
}

/**
 *
 * @param sourceUri   The URI that is searched with the pattern found in the set of shureCloudUriMap values.
 * @param An array of pattern/redirect values to search. The array elements are searched in order.
 *        The first element whose pattern is found in `sourceUri` is used, and it's shureCloudUri value is returned.
 *        If no match is found, undefined is returned.
 * @returns
 */
export function getShureCloudUri(
	sourceUri: string,
	shureCloudMap: AppEnvironment['shureCloudUriMap']
): string | undefined {
	const mapEntry = shureCloudMap?.filter((item) => sourceUri.includes(item.urlPattern));
	if (mapEntry && mapEntry.length > 0) {
		return mapEntry[0].shureCloudUri;
	}
	return undefined;
}
