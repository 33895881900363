import { proxyCustomElement, HTMLElement, h } from '@stencil/core/internal/client';
import { d as defineCustomElement$2 } from './prism-icon2.js';

const prismIconButtonCss = ":host{display:inline-block;line-height:0;--_size:var(--prism-icon-button-size, 2rem);}:host([size=sm]){--_size:var(--prism-icon-button-size, 1.5rem);}:host([disabled]){pointer-events:none}button{display:inline-grid;width:var(--_size);height:var(--_size);align-items:stretch;cursor:pointer;pointer-events:auto;padding:0;background:none;border:none;outline:none;border-radius:50%;overflow:hidden}button>*{grid-area:1/1/-1/-1}.pwc-window-film{pointer-events:none;border-radius:inherit;overflow:inherit}.pwc-icon-container{padding:12.5%}.pwc-icon{color:var(--prism-color-theme-base-contrast);width:100%;height:100%;--prism-icon-size:100%}button:hover>.pwc-window-film{background-color:var(--prism-color-theme-window-film-hover)}button:active>.pwc-window-film{background-color:var(--prism-color-theme-window-film-pressed)}button:focus-visible>.pwc-window-film{background-color:var(--prism-color-theme-window-film-focused)}button:disabled{pointer-events:none}button:disabled .pwc-icon{color:var(--prism-color-theme-disabled-contrast)}";
const PrismIconButtonStyle0 = prismIconButtonCss;

const PrismIconButton$1 = /*@__PURE__*/ proxyCustomElement(class PrismIconButton extends HTMLElement {
    constructor() {
        super();
        this.__registerHost();
        this.__attachShadow();
        this.icon = undefined;
        this.size = 'md';
        this.customIcon = undefined;
        this.disabled = false;
        this.label = undefined;
    }
    render() {
        return (h("button", { key: 'b8e9940a0bd84856f99de3898cfb45352e1e41e4', disabled: this.disabled, "aria-label": this.label }, h("div", { key: '7da5c66a654d2d6b0cee0f2f1e30aa7e777bc8f1', class: "pwc-window-film" }), h("div", { key: 'b2cd385604eb210b6d0750a3747b715abd0724a8', class: "pwc-icon-container" }, h("prism-icon", { key: '4643ecac16a4dad92a531519d1a08caf2f4725fb', icon: this.icon, customIcon: this.customIcon, class: "pwc-icon", "aria-hidden": "true" }))));
    }
    static get delegatesFocus() { return true; }
    static get style() { return PrismIconButtonStyle0; }
}, [17, "prism-icon-button", {
        "icon": [1],
        "size": [513],
        "customIcon": [1, "custom-icon"],
        "disabled": [516],
        "label": [1]
    }]);
function defineCustomElement$1() {
    if (typeof customElements === "undefined") {
        return;
    }
    const components = ["prism-icon-button", "prism-icon"];
    components.forEach(tagName => { switch (tagName) {
        case "prism-icon-button":
            if (!customElements.get(tagName)) {
                customElements.define(tagName, PrismIconButton$1);
            }
            break;
        case "prism-icon":
            if (!customElements.get(tagName)) {
                defineCustomElement$2();
            }
            break;
    } });
}

const PrismIconButton = PrismIconButton$1;
const defineCustomElement = defineCustomElement$1;

export { PrismIconButton, defineCustomElement };

