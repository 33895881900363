<sh-app-access-not-configured
	*ngIf="(secondaryNavService.getIsPermissionAvailable$() | async) === false"
></sh-app-access-not-configured>
<ng-container *ngIf="(secondaryNavService.getIsPermissionAvailable$() | async) === true">
	<ng-container *ngIf="getSecondaryNavListItems$ | async as secondaryNavListItems">
		<ng-container *ngIf="secondaryNavListItems.length > 0">
			<sh-secnav-layout *transloco="let t" [isCloseButtonEnabled]="false" class="sh-secondary-nav-home">
				<sh-secnav-layout-secnav>
					<span id="sh-secondary-nav-header">{{
						t('cloud.shure-cloud.license-management.licenses-and-subscriptions')
					}}</span>
					<mat-nav-list *ngFor="let secondaryNavListItem of getSecondaryNavListItems$ | async">
						<mat-list-item
							(click)="navigateToPath(secondaryNavListItem)"
							id="sh-secondary-nav-{{ secondaryNavListItem.id }}"
						>
							{{ secondaryNavListItem.listItem }}
						</mat-list-item>
					</mat-nav-list>
				</sh-secnav-layout-secnav>
				<sh-secnav-layout-content>
					<div id="sh-secondary-nav-content-div">
						<div id="sh-secondary-nav-right-content-div">
							<ng-container *ngIf="activeListItem$ | async as activeListItem">
								<sh-header-toolbar
									[showSidenavToggleButton]="true"
									[showBreadCrumbs]="false"
									[secondaryNavHeader]="t('cloud.shure-cloud.license-management.' + activeListItem)"
									[showFeatureButton]="!isSmallDevice() && !isLandscape()"
									(toggleSidenav)="openSidenav()"
								></sh-header-toolbar>
							</ng-container>
							<sh-breadcrumbs
								*ngIf="breadCrumbs().length"
								[breadcrumbs]="breadCrumbs()"
								(breadcrumbClick)="breadCrumbRedirect($event)"
							></sh-breadcrumbs>
							<router-outlet></router-outlet>
						</div>
						<div id="sh-secondary-nav-footer-content-div">
							<sh-footer></sh-footer>
						</div>
					</div>
				</sh-secnav-layout-content>
			</sh-secnav-layout>
		</ng-container>
		<ng-container *ngIf="secondaryNavListItems.length === 0">
			<div id="sh-secondary-nav-unavailable-container-div" *transloco="let t">
				<img
					id="sh-secondary-nav-unavailable-img"
					[src]="listItemUnavailableContent.imageSrc | version | async"
				/>
				<div id="sh-secondary-nav-unavailable-sub-title-head-div">{{ listItemUnavailableContent.header }}</div>
				<div id="sh-secondary-nav-unavailable-sub-title-text-div">{{ listItemUnavailableContent.text }}</div>
			</div>
		</ng-container>
	</ng-container>
</ng-container>
