export enum RFDensityModeOptions {
	Standard = 'Standard',
	High = 'High'
}

export enum RFPowerOptions {
	Low = 'Low',
	Medium = 'Medium',
	MediumHigh = 'Medium High',
	High = 'High'
}
