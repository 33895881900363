export enum SwitchBehaviorOptions {
	Toggle = 'Toggle',
	PushToTalk = 'Push to Talk',
	PushToMute = 'Push to Mute',
	Disabled = 'Disabled'
}

export enum InitialStateOptions {
	Off = 'Off',
	Active = 'Active'
}

export enum ActiveMuteLEDBehavior {
	SolidGreenSolidRed = 'Solid Green / Solid Red',
	SolidRedOff = 'Solid Red / Off',
	SolidRedFlashingRed = 'Solid Red / Flashing Red'
}
