/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface GetAccountInfo$Params {
	projections?: string[];
}

export function getAccountInfo(
	http: HttpClient,
	rootUrl: string,
	params?: GetAccountInfo$Params,
	context?: HttpContext
): Observable<StrictHttpResponse<void>> {
	const rb = new RequestBuilder(rootUrl, getAccountInfo.PATH, 'get');
	if (params) {
		rb.query('projections', params.projections, {});
	}

	return http.request(rb.build({ responseType: 'text', accept: '*/*', context })).pipe(
		filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
		map((r: HttpResponse<any>) => {
			return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
		})
	);
}

getAccountInfo.PATH = '/api/v1/account-info';
