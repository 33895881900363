import { DeviceCompatibilityLevel } from './device-compatibility';
import { DanteNetworkAccess } from './device-dante-network-aceess';
import { DeviceLicense, DeviceLicenseState } from './device-license';
import { DeviceState } from './device-state.enum';
import { MissingFeatureType } from './missing-feature-type';

export type DeviceWithPotentialErrors = {
	deviceState: DeviceState;
	compatibility: DeviceCompatibilityLevel;
	virtual?: boolean;
	features: {
		danteNetworkAccess?: DanteNetworkAccess & MissingFeatureType;
		firmware?: {
			valid: boolean;
		} & MissingFeatureType;
		license?: DeviceLicense<unknown> & MissingFeatureType;
		virtual?: {
			virtual: boolean;
		} & MissingFeatureType;
	};
};

export enum DeviceErrorType {
	None = 'NONE',
	Offline = 'OFFLINE',
	DiscoveryError = 'DISCOVERY_ERROR',
	IncompatibleFirmware = 'INCOMPATIBLE_FIRMWARE',
	LicenseError = 'LICENSE_ERROR',
	PoE = 'POE',
	InvalidFirmware = 'INVALID_FIRMWARE',
	DanteLocked = 'DANTE_LOCKED',
	LicenseWarning = 'LICENSE_WARNING',
	LoudspeakerOff = 'LOUDSPEAKER_OFF',
	NewDevice = 'NEW_DEVICE',
	EncryptionError = 'ENCRYPTION_ERROR',
	WeakRFStrengthError = 'WEAK_RF_STRENGTH_ERROR',
	OutOfRangeError = 'OUT_OF_RANGE_ERROR',
	ChargingError = 'CHARGING_ERROR',
	LowBatteryWarning = 'LOW_BATTERY_WARNING',
	IncompatibleAPXWithMXWWarning = 'INCOMPATIBLE_APX_MXW_WARNING',
	IncompatibleNDXWithMXWWarning = 'INCOMPATIBLE_NDX_MXW_WARNING'
}

export enum ErrorLevel {
	Error = 'ERROR',
	Warning = 'WARNING'
}

export type LoudspeakerError = DeviceErrorType.PoE | DeviceErrorType.LoudspeakerOff;

export type MXWError =
	| DeviceErrorType.Offline
	| DeviceErrorType.WeakRFStrengthError
	| DeviceErrorType.OutOfRangeError
	| DeviceErrorType.ChargingError
	| DeviceErrorType.LowBatteryWarning
	| DeviceErrorType.IncompatibleAPXWithMXWWarning
	| DeviceErrorType.IncompatibleNDXWithMXWWarning;

export const errorTypeToPriority: Record<DeviceErrorType, number> = {
	[DeviceErrorType.NewDevice]: 0,
	[DeviceErrorType.Offline]: 1,
	[DeviceErrorType.DiscoveryError]: 2,
	[DeviceErrorType.IncompatibleFirmware]: 3,
	[DeviceErrorType.LicenseError]: 4,
	[DeviceErrorType.EncryptionError]: 5,
	[DeviceErrorType.PoE]: 6,
	[DeviceErrorType.InvalidFirmware]: 7,
	[DeviceErrorType.DanteLocked]: 8,
	[DeviceErrorType.LicenseWarning]: 9,
	[DeviceErrorType.LoudspeakerOff]: 10,
	[DeviceErrorType.OutOfRangeError]: 11,
	[DeviceErrorType.WeakRFStrengthError]: 12,
	[DeviceErrorType.ChargingError]: 13,
	[DeviceErrorType.LowBatteryWarning]: 14,
	[DeviceErrorType.IncompatibleAPXWithMXWWarning]: 15,
	[DeviceErrorType.IncompatibleNDXWithMXWWarning]: 16,
	[DeviceErrorType.None]: 99
};

export const licenseStateToErrorType: Record<DeviceLicenseState, DeviceErrorType | null> = {
	[DeviceLicenseState.Unlicensed]: DeviceErrorType.LicenseError,
	[DeviceLicenseState.ActivationConfirmed]: null,
	[DeviceLicenseState.GracePeriod]: DeviceErrorType.LicenseWarning,
	[DeviceLicenseState.WarningPeriod]: DeviceErrorType.LicenseError,
	[DeviceLicenseState.Expired]: DeviceErrorType.LicenseError,
	[DeviceLicenseState.CommunicationIssue]: DeviceErrorType.LicenseError,
	[DeviceLicenseState.Insufficient]: DeviceErrorType.LicenseError,
	[DeviceLicenseState.ClockSetting]: DeviceErrorType.LicenseError,
	[DeviceLicenseState.InvalidId]: DeviceErrorType.LicenseError,
	[DeviceLicenseState.ActivationPending]: DeviceErrorType.LicenseError,
	[DeviceLicenseState.DeactivationPending]: DeviceErrorType.LicenseError,
	[DeviceLicenseState.UnknownIssue]: DeviceErrorType.LicenseError
};
