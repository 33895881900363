<button
	[disabled]="disabled"
	mat-icon-button
	class="sh-id-button"
	[class.sh-device-identifying]="isIdentifying"
	(click)="identifyDevice($event)"
>
	<!-- The span is required to prevent additional whitespace from being added around the "ID" text -->
	<span class="id-text">ID</span>
</button>
